import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionService {

  timeVersionLastChecked = 0;

  constructor(private http: HttpClient) {}

  async checkVersion() {

    try{ 
         navigator.serviceWorker.getRegistrations()
      .then(registrations => {
        registrations.map(r => {

          r.unregister();
        });
      })} catch {
      }


    if (!document.hidden) {
    const now = Date.now();
    this.timeVersionLastChecked = Number(localStorage.getItem('time-version-last-checked'));
    if (this.timeVersionLastChecked === null) {
      this.timeVersionLastChecked = 0;
    }

    if ((now-this.timeVersionLastChecked)>60000) {
      localStorage.setItem('time-version-last-checked', String(now));
      const url = '/api/HttpVersion';
      const versionHttp = await firstValueFrom(this.http.get<any>(
        url
      ));
      const version = versionHttp.version;

      if (environment.version !== version) {
        document.location.reload();
      }
    }
  }
}




}